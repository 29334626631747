import {
  getTenant,
  getUserId,
  IBLSpinLoader,
} from '@iblai/ibl-web-react-common';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import EmptyTableDataInfographic from '../../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic';

const CredentialsTable = ({
  refreshCredential,
  courseID,
  credentialData,
  setCredentialData,
  setCreateCredentialPopupOpen,
}) => {
  const [credentials, setCredentials] = useState([]);
  const [credentialLoaded, setCredentialLoaded] = useState(false);

  useEffect(() => {
    fetchCredentials();
  }, [refreshCredential]);

  const fetchCredentials = async () => {
    try {
      const url = `${process.env.REACT_APP_IBL_DM_URL}/api/credentials/orgs/${getTenant()}/users/${getUserId()}/?course=${encodeURIComponent(courseID)}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('axd_token')}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      setCredentials(data?.result?.data || []);
    } catch (err) {
      console.error('Error fetching credentials:', err);
      setCredentials([]);
    } finally {
      setCredentialLoaded(true);
    }
  };

  const editCredential = (credentialInstance) => {
    setCredentialData({
      is_update: true,
      entityId: credentialInstance.entityId,
      issuer: credentialInstance.issuerDetails.entityId,
      name: credentialInstance.name,
      description: credentialInstance.description,
      iconImage: credentialInstance.iconImage || '',
      thumbnailImage: credentialInstance.thumbnailImage || '',
      backgroundImage: credentialInstance.backgroundImage || '',
      credentialType: credentialInstance.credentialType,
      criteriaNarrative: credentialInstance.criteriaNarrative,
      criteriaUrl: credentialInstance.criteriaUrl,
      courses: credentialInstance.courses.map((course) => course.course_id),
      html_template: credentialInstance.html_template || '',
      css_template: credentialInstance.css_template || '',
      signal: credentialInstance.signal || '',
    });
    setCreateCredentialPopupOpen(true);
  };

  return (
    <div className="table_cont mgt0">
      <div className="div-block-202 w_t w_w">
        <div className="div-block-169">
          <div>
            <div className="text-block-109">Credentials</div>
          </div>
        </div>

        <div className="table">
          <div className="th">
            <div className="tc _10">
              <div className="sm">#</div>
            </div>
            <div className="tc _20">
              <div className="w_sort">
                <div className="text-block-134 fa_icon sort"></div>
                <div className="sm">Name</div>
              </div>
            </div>
            <div className="tc _30">
              <div className="w_sort">
                <div className="text-block-134 fa_icon sort"></div>
                <div className="sm">Entity ID</div>
              </div>
            </div>
            <div className="tc _10">
              <div className="w_sort">
                <div className="text-block-134 fa_icon sort"></div>
                <div className="sm">Issuer</div>
              </div>
            </div>
            <div className="tc _20">
              <div className="w_sort">
                <div className="text-block-134 fa_icon sort"></div>
                <div className="sm">Credential Type</div>
              </div>
            </div>
            <div className="tc _10">
              <div className="w_sort">
                <div className="text-block-134 fa_icon sort"></div>
                <div className="sm">Edit</div>
              </div>
            </div>
          </div>
          {!credentialLoaded ? (
            <IBLSpinLoader color={'#FFF'} size={20} containerHeight={'200px'} />
          ) : credentials.length === 0 ? (
            <EmptyTableDataInfographic />
          ) : (
            credentials.map((credential, index) => (
              <div className="tr" key={credential.entityId}>
                <div className="tc tcell _10">
                  <div className="m">{index + 1}</div>
                </div>
                <div className="tc tcell _20">
                  <img
                    src={
                      credential.iconImage ||
                      '/static/media/c4s.8d5b06c3201e869bcf18.jpeg'
                    }
                    alt=""
                    className="r-i"
                    loading="lazy"
                  />
                  <NavLink
                    className="tl-2 np"
                    to={`/studio/authoring/assertions/${courseID}/${credential.entityId}`}
                    rel="noopener noreferrer"
                  >
                    <span className="nb" prop-coursetitle="">
                      {credential.name}
                    </span>
                  </NavLink>
                </div>
                <div className="tc tcell _30">
                  <div className="m">{credential.entityId}</div>
                </div>
                <div className="tc tcell _10">
                  <div className="m">{credential.issuerDetails.name}</div>
                </div>
                <div className="tc tcell _20">
                  <div className="m">{credential.credentialType}</div>
                </div>
                <div className="tc tcell _10">
                  <NavLink
                    to="#"
                    onClick={() => editCredential(credential)}
                    className="link-block-22 w-inline-block"
                  >
                    <div className="div-block-207 first active follow-user">
                      <div className="text-block-111">Edit</div>
                    </div>
                  </NavLink>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CredentialsTable;
