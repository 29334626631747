import {useEffect, useState} from 'react';
import {
    assignLicense,
    assignUserToGroup,
    createOrUpdateUserGroup, getAssignmentsPerLicense,
    getLicensesList,
    getLicensingUserGroupList,
    getUsersList
} from "./utils/licensing";
import {useDebounce} from "./useDebounce";
import {slugify} from "./helpers";  // Replace with the correct path

const useAssignmentsPerLicense = ({platformType="user", isGroup=false, license, refresh=null, page=1, size=10}) => {

    const [assignments, setAssignments] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });

    const handleFetchSuccess = (data) => {
        if(Array.isArray(data?.results)){
            setAssignments(data?.results)
        }
        setPagination({
            totalItems:  data?.count,
            currentPage: page,
            totalPages: Math.ceil(data?.count / size),
            pageSize: size,
        });
        setLoading(false)
    }

    const unAssignLicense = ({assignment, isGroup, handleUnAssignmentCallback}) => {
        assignLicense(platformType, {assignment_id:assignment?.id}, handleUnAssignmentCallback, isGroup, true)
    }

    useDebounce(()=>{
        setLoading(true)
        getAssignmentsPerLicense({license_id:license?.id, page_size:size,
            page}, platformType, handleFetchSuccess, ()=>{}, isGroup )
    }, 200, [isGroup, refresh, page])

    return {
        assignments,
        loading,
        unAssignLicense,
        pagination
    }


};

export default useAssignmentsPerLicense;
