import {useEffect, useState} from 'react';
import Async from 'react-select/async';
import Select from 'react-select';

import {
  getTenant,
  getUserId,
  IBLSpinLoader, isJSON,
  isValidHttpUrl,
} from '@iblai/ibl-web-react-common';

const CreateCredential = ({
  credentialData,
  setCredentialData,
  setRefreshCredential,
  courseID,
  notification,
  setNotification,
  loading,
  setLoading,
  setCreateCredentialPopupOpen,
}) => {


  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentialData({
      ...credentialData,
      [name]: value,
    });
  };

const getCredentialIssuers = async () => {
  const currentTenant = localStorage.getItem("current_tenant")
  const tenantOrg = isJSON(currentTenant) ? JSON.parse(currentTenant)?.org : null
  const url = `${process.env.REACT_APP_IBL_DM_URL}/api/credentials/orgs/${getTenant()}/users/${getUserId()}/issuers/${tenantOrg}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('axd_token')}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch credential issuers');
    }

    const data = await response.json(); 
    return data || []; 
  } catch (err) {
    console.error('Error fetching credentials:', err);
    return [];
  }
};

const [issuersListForDropdown, setIssuersListForDropdown] = useState([])


  const loadIssuerOptions = () => {
    return getCredentialIssuers().then((data) => {
      const issueList = data.map((issuer) => ({
        value: issuer.entityId,
        label: issuer.name,
      }))
      setIssuersListForDropdown(issueList)
      return issueList;
    });
  };

  const handleIssuerChange = (issuer) => {
    setCredentialData({ ...credentialData, issuer: issuer.value });
  };

  const credentialTypeOptions = [
    { value: 'MICROCREDENTIAL', label: 'Microcredential' },
    { value: 'COURSE_CERTIFICATE', label: 'Course Certificate' },
  ];

  const issuingSinalOptions = [
    { value: 'COURSE_COMPLETED', label: 'Course Completed' },
    { value: 'COURSE_PASSED', label: 'Course Passed' },
  ];

  const handleIssuingSignalChange = (signal) => {
    setCredentialData({
      ...credentialData,
      signal: signal.value,
    });
  };


  const handleCredentialTypeChange = (credentialType) => {
    setCredentialData({
      ...credentialData,
      credentialType: credentialType.value,
    });
  };

const postCreateCredential = async (data) => {
  const url = `${process.env.REACT_APP_IBL_DM_URL}/api/credentials/orgs/${getTenant()}/users/${getUserId()}/`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('axd_token')}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      setNotification({
        success: false,
        msg: 'Something went wrong, please try again.',
      });
      
      throw new Error('Failed to create credential');
    }



    // Reset credential data
    setCredentialData({
      is_update : false,
      issuer: '',
      name: '',
      description: '',
      iconImage: '',
      thumbnailImage: '',
      backgroundImage: '',
      credentialType: '',
      signal: '',
      criteriaNarrative: '',
      criteriaUrl: '',
      courses: [courseID],
      html_template: '',
      ccs_template: '',
    });

    // You can uncomment the notification line if you want to set it
    // setNotification({
    //   success: true,
    //   msg: 'Credential created successfully',
    // });

    setRefreshCredential((prev) => !prev);
    window.alert('Credential created successfully.');
  
  } catch (err) {
    setNotification({
      success: false,
      msg: err.message || 'Something went wrong, please try again.',
    });
    
  } finally {
    setLoading(false);
  }
};

  const updateCredential = async (data) => {
     const url = `${process.env.REACT_APP_IBL_DM_URL}/api/credentials/orgs/${getTenant()}/users/${getUserId()}/${data.entityId}`;

     try {
       const response = await fetch(url, {
         method: 'PUT',
         headers: {
           'Content-Type': 'application/json',
           Authorization: `Token ${localStorage.getItem('axd_token')}`,
         },
         body: JSON.stringify(data),
       });

       if (!response.ok) {
         setNotification({
           success: false,
           msg: 'Something went wrong, please try again.',
         });

         throw new Error('Failed to update credential');
       }

       // Reset credential data
       setCredentialData({
         is_update : false,
         issuer: '',
         name: '',
         description: '',
         iconImage: '',
         thumbnailImage: '',
         backgroundImage: '',
         credentialType: '',
         signal:"",
         criteriaNarrative: '',
         criteriaUrl: '',
         courses: [courseID],
         html_template: '',
         ccs_template: '',
       });

       // You can uncomment the notification line if you want to set it
       // setNotification({
       //   success: true,
       //   msg: 'Credential created successfully',
       // });

       setRefreshCredential((prev) => !prev);
       window.alert('Credential updated successfully.');
     } catch (err) {
       setNotification({
         success: false,
         msg: err.message || 'Something went wrong, please try again.',
       });
       
     } finally {
       setLoading(false);
     }
  };

  useEffect(() => {
    // Clear notification when any input changes
    if (credentialData) {
      setNotification({ success: null, msg: null });
    }
  }, [credentialData, courseID]);

  const handleSubmit = () => {
    const {
      issuer,
      name,
      description,
      iconImage,
      credentialType,
      backgroundImage,
    } = credentialData;

    if (
      !issuer ||
      !name ||
      !description ||
      !iconImage ||
      !credentialType ||
      !backgroundImage
    ) {
      setNotification({
        success: false,
        msg: 'All fields must be filled.',
      });
      return;
    }

    // icon mage and background image must be valid urls
    if (!isValidHttpUrl(iconImage)) {
      setNotification({
        success: false,
        msg: 'Icon Image must be a valid URL',
      });
      return;
    }

    if (!isValidHttpUrl(backgroundImage)) {
      setNotification({
        success: false,
        msg: 'Background Image must be a valid URL',
      });
      return;
    }

    setLoading(true);
    if(credentialData.is_update){
      updateCredential(credentialData);
    }else{
      postCreateCredential(credentialData);
    }
  };

  const handleClose = () => {
    setCreateCredentialPopupOpen(false);
     setCredentialData({
       is_update: false,
       issuer: '',
       name: '',
       description: '',
       iconImage: '',
       thumbnailImage: '',
       backgroundImage: '',
       credentialType: '',
       criteriaNarrative: '',
       criteriaUrl: '',
       courses: [courseID],
       html_template: '',
       ccs_template: '',
     });
     setNotification({ success: null, msg: null });
  };

  return (
    <div
      style={{ display: 'flex' }}
      className="popup_div-nm_popup create-credential-popup"
    >
      <div className="popup_cont-sk">
        <div className="hf_popup" style={{height: '600px', overflowY: 'auto'}}>
          <div className="div-block-147 new_msg">
            <div className="text-block-135 mg-0">{credentialData.is_update ? 'Update Credential' : 'Create Credential'}</div>
            {notification?.msg && (
              <div
                className={`license-notification-block ${!notification?.success ? 'error' : ''}`}
              >
                {notification?.msg}
              </div>
            )}
          </div>
          <div className="tabs w-tabs">
            <div className="w-tab-content">
              <div className="w-tab-pane w--tab-active">
                <div className="div-block-228 less-pad">
                  <div className="form-block-7 w-form">
                    <form>
                      <label
                          htmlFor="issuer"
                          className="text-block-139-bd-f-soc"
                      >
                        Issuer *
                      </label>
                      <Async
                          cacheOptions
                          classNamePrefix="react-select"
                          defaultOptions
                          loadOptions={loadIssuerOptions}
                          onChange={handleIssuerChange}
                          isSearchable={false}
                          value={issuersListForDropdown.find(option => option.value === credentialData?.issuer)}
                      />

                      <label htmlFor="name" className="text-block-139-bd-f-soc">
                        Credential Name *
                      </label>
                      <input
                          className="text-field-3 w-input"
                          name="name"
                          value={credentialData.name}
                          onChange={handleInputChange}
                      />

                      <label
                          htmlFor="description"
                          className="text-block-139-bd-f-soc"
                      >
                        Description *
                      </label>
                      <input
                          className="text-field-3 w-input"
                          name="description"
                          value={credentialData.description}
                          onChange={handleInputChange}
                      />

                      <label
                          htmlFor="credentialType"
                          className="text-block-139-bd-f-soc"
                      >
                        Credential Type *
                      </label>
                      <Select
                          classNamePrefix="react-select"
                          name="credentialType"
                          options={credentialTypeOptions} // Static options go here
                          onChange={handleCredentialTypeChange}
                          isSearchable={false} // Disable typing ability
                          value={credentialTypeOptions.find(option => option.value === credentialData?.credentialType)}
                      />
                      <label
                          htmlFor="credentialType"
                          className="text-block-139-bd-f-soc"
                      >
                        Issuing Signal
                      </label>
                      <Select
                          classNamePrefix="react-select"
                          name="signal"
                          options={issuingSinalOptions} // Static options go here
                          onChange={handleIssuingSignalChange}
                          isSearchable={false} // Disable typing ability
                          value={issuingSinalOptions.find(option => option.value === credentialData?.signal)}
                      />
                      <label
                          htmlFor="iconImage"
                          className="text-block-139-bd-f-soc"
                      >
                        Icon Image URL *
                      </label>
                      <input
                          className="text-field-3 w-input"
                          name="iconImage"
                          value={credentialData.iconImage}
                          onChange={handleInputChange}
                      />

                      <label
                          htmlFor="backgroundImage"
                          className="text-block-139-bd-f-soc"
                      >
                        Background Image URL *
                      </label>
                      <input
                          className="text-field-3 w-input"
                          name="backgroundImage"
                          value={credentialData.backgroundImage}
                          onChange={handleInputChange}
                      />

                      <label
                          htmlFor="courses"
                          className="text-block-139-bd-f-soc"
                      >
                        Course *
                      </label>
                      <input
                          className="text-field-3 w-input"
                          name="courseId"
                          data-name="Course ID"
                          placeholder="Enter the Course ID"
                          type="text"
                          id="course-id"
                          style={{backgroundColor: 'transparent'}}
                          value={courseID}
                          readOnly
                      />

                      <label
                          htmlFor="html_template"
                          className="text-block-139-bd-f-soc"
                      >
                        HTML Template
                      </label>
                      <input
                          className="text-field-3 w-input"
                          name="html_template"
                          value={credentialData.html_template}
                          onChange={handleInputChange}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="div-block-147 new_msg">
            <div className="div-block-264 f-w">
              <div
                  onClick={handleClose}
                  data-w-id="04b212b6-6908-556e-c3c3-d1f2508be27f"
                  className="div-block-207 first active follow-user alt-btn cancel-invite"
              >
                <div className="text-block-111 red-text cf">Close</div>
              </div>
              <div
                  onClick={handleSubmit}
                  className="div-block-207 first active follow-user redeem-btn mg-l16"
              >
                <div className="text-block-111 red-text cf">{credentialData.is_update ? 'Update' : 'Create'}</div>
                {loading && (
                  <span style={{ marginLeft: '10px' }}>
                    <IBLSpinLoader size={15} color={'#FFF'} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCredential;
